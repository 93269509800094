/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';


/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/*Importes para el uso del swiper/slider*/
// @import "~swiper/scss";
// @import '~@ionic/angular/css/ionic-swiper';
// @import "~swiper/scss/autoplay";
// @import "~swiper/scss/pagination";
// @import "~swiper/scss/keyboard";
// @import "~swiper/scss/scrollbar";
// @import "~swiper/scss/zoom";

.modal-half-screen {
  // --width: 42%;
  --height: 50vh;
  --border-radius: 10px;
}

ion-modal::part(content) {
  background-color: rgb(59, 63, 81);
}

.balas>.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
  height: 30px;
  width: 30px;
  border: 3px solid black;
  background-color: #ffffff;
}

.vertical {
  display: flex;
  align-items: center;
  justify-content: center;
}

.verticalRight {
  display: flex;
  align-items: center;
  justify-content: right;
}

.center {
  text-align: center;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.verticalLeft {
  display: flex;
  align-items: center;
  justify-content: left;
}

.version-text {
  align-self: flex-end;
  color: grey;
  font-size: 12px;
  padding-left: 10px;
}